import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { numberToCurrency } from '@/shared/helpers/String'
import { setQuantity, selectQuantity } from '@/public/redux/productSlice'
import QuantityInput from '@/public/product/QuantityInput'
import AddToCartButton from '@/public/product/AddToCartButton'
import PolicyLinks from '@/public/product/PolicyLinks'
import PropTypes from 'prop-types'
import NotifyWhenAvailable from './NotifyWhenAvailable'

export default function PriceBlockWithQuantity({
  product,
  returnPolicy,
  shippingPolicy,
  customerIsMember,
  awaitedProductIds,
  restricted,
  accessUrl,
}) {
  const dispatch = useDispatch()
  const quantity = useSelector(selectQuantity)
  const backOrdered = /back_ordered/i.test(product.status)

  return (
    <div className="card">
      <div className="card-content pt-4">
        {
          !backOrdered && (
            <>
              <p className="subtitle secondary-d50">Quantity</p>

              <QuantityInput
                quantity={quantity}
                handleChange={(newQuantity) => dispatch(setQuantity(newQuantity))}
              />

              <hr />
            </>
          )
        }

        <div className="subtitle bold">
          {
            backOrdered ? (
              <>
                <del>{numberToCurrency(product.price)}</del>
                <span className="text-opacity-50 ms-3 text-secondary small fw-normal">Sold Out</span>
              </>
            ) : (
              <div className="subtitle bold">
                {numberToCurrency(product.price)}
              </div>
            )
          }
        </div>

        <div className="actions mt-3">
          {
            backOrdered ? (
              <NotifyWhenAvailable product={product} awaitedProductIds={awaitedProductIds} wide />
            ) : (
              <>
                {restricted && !customerIsMember && (
                  <a href={accessUrl} className="btn btn-primary btn-block">Join For Access</a>
                )}

                {!restricted
                  && (
                    <AddToCartButton
                      product={product}
                      quantity={quantity}
                      className="btn btn-primary btn-block"
                    />
                  )}

                {product.is_subscribable && customerIsMember
                  && (
                    <AddToCartButton product={product} className="btn btn-subscription btn-block mt-3" subscription>
                      Subscribe
                    </AddToCartButton>
                  )}

                {product.is_subscribable && !customerIsMember
                  && (
                    <AddToCartButton product={product} className="btn btn-subscription btn-block mt-3" subscription>
                      Subscribe & Save
                      {' '}
                      {product.calculated_subscription_savings_percentage}
                    </AddToCartButton>
                  )}
              </>
            )
          }

          <PolicyLinks
            product={product}
            shippingPolicy={shippingPolicy}
            returnPolicy={returnPolicy}
          />
        </div>
      </div>
    </div>
  )
}

PriceBlockWithQuantity.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.string,
    price: PropTypes.string,
    is_subscribable: PropTypes.bool,
    calculated_subscription_savings_percentage: PropTypes.string,
  }).isRequired,
  returnPolicy: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
  }),
  shippingPolicy: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
  }),
  customerIsMember: PropTypes.bool,
  restricted: PropTypes.bool.isRequired,
  awaitedProductIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  accessUrl: PropTypes.string.isRequired,
}
PriceBlockWithQuantity.defaultProps = {
  returnPolicy: null,
  shippingPolicy: null,
  customerIsMember: null,
}
