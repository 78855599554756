export * from './what-to-expect'
export * from './tab'

function toggleMonthlyAnnualPrice(topLevelClass, priceHash) {
  const toggleSwitch = document.querySelector(`${topLevelClass} .toggle .switch input[type=checkbox]`)
  if (!toggleSwitch) {
    return
  }
  const price = document.querySelector(`${topLevelClass} .price h2`)
  const monthlyOrAnnual = document.querySelector(`${topLevelClass} .price .frequency`)
  toggleSwitch.addEventListener('change', (e) => {
    if (e.target.checked) {
      price.innerHTML = priceHash.annual
      monthlyOrAnnual.innerHTML = '/year'
    } else {
      price.innerHTML = priceHash.monthly
      monthlyOrAnnual.innerHTML = '/month'
    }
  })
}

document.addEventListener('DOMContentLoaded', () => {
  toggleMonthlyAnnualPrice('.level.premium', { annual: '4995', monthly: '595' })
  toggleMonthlyAnnualPrice('.level.access', { annual: '199', monthly: '29.99' })
})
