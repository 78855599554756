import React from 'react'
import { numberToCurrency } from '@/shared/helpers/String'
import AddToCartButton from '@/public/product/AddToCartButton'
import ApplicationButton from '@/public/product/ApplicationButton'
import PolicyLinks from '@/public/product/PolicyLinks'
import PropTypes from 'prop-types'

export default function PriceBlockWithoutQuantity({
  product,
  restricted,
  returnPolicy,
  shippingPolicy,
  planTerms,
  customerIsMember,
  accessUrl,
  debug = false,
}) {
  return (
    <div className="card">
      <div className="card-content pt-4">
        <div className="subtitle bold">
          {numberToCurrency(product.price)}
        </div>

        <div className="actions mt-3">
          {product.is_applicable
            && (
              <ApplicationButton
                product={product}
                button_text={product.button_text}
                product_url={product.application_url}
                target="_blank"
                className="btn btn-primary btn-block"
              />
            )}

          {product.is_purchasable && restricted && !customerIsMember && (
            <a href={accessUrl} className="btn btn-primary btn-block">Join For Access</a>
          )}

          {product.is_purchasable && !restricted
            && (
              <AddToCartButton
                product={product}
                className="btn btn-primary btn-block"
              />
            )}

          <PolicyLinks
            product={product}
            shippingPolicy={shippingPolicy}
            returnPolicy={returnPolicy}
            plan_terms={planTerms}
          />
        </div>
      </div>
    </div>
  )
}

PriceBlockWithoutQuantity.propTypes = {
  product: PropTypes.shape({
    price: PropTypes.string,
    is_applicable: PropTypes.bool,
    button_text: PropTypes.string,
    application_url: PropTypes.string,
    is_purchasable: PropTypes.bool,
  }).isRequired,
  restricted: PropTypes.bool,
  returnPolicy: PropTypes.string,
  shippingPolicy: PropTypes.string,
  planTerms: PropTypes.string,
  customerIsMember: PropTypes.bool,
  accessUrl: PropTypes.string.isRequired,
}
PriceBlockWithoutQuantity.defaultProps = {
  restricted: null,
  returnPolicy: null,
  shippingPolicy: null,
  planTerms: null,
  customerIsMember: null,
}
