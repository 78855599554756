import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { numberToCurrency } from '@/shared/helpers/String'
import { setQuantity, selectQuantity } from '@/public/redux/productSlice'
import QuantityInput from '@/public/product/QuantityInput'
import AddToCartButton from '@/public/product/AddToCartButton'
import ApplicationButton from '@/public/product/ApplicationButton'
import PropTypes from 'prop-types'

export default function ProductUtilityActions({
  product, customerIsMember, restricted, accessUrl, debug = false,
}) {
  const dispatch = useDispatch()
  const quantity = useSelector(selectQuantity)

  return /back_ordered/i.test(product.status) ? '' : (
    <>
      { product.quantifiable
          && (
          <div className="border-dash-right pe-lg-4 me-lg-4">
            <QuantityInput
              quantity={quantity}
              handleChange={(quantity) => dispatch(setQuantity(quantity))}
            />
          </div>
          )}

      { product.is_applicable
          && (
          <ApplicationButton
            product={product}
            button_text={product.button_text}
            product_url={product.application_url}
            target="_blank"
            className="btn btn-primary btn-block"
          />
          )}

      { product.is_purchasable && restricted && !customerIsMember && (
        <a href={accessUrl} target="_blank" className="btn btn-primary btn-block" rel="noreferrer">Join For Access</a>
      )}

      { product.is_purchasable && !restricted && (
        <AddToCartButton product={product} quantity={quantity}>
          Add to Cart
          {` - ${numberToCurrency(product.price)}`}
        </AddToCartButton>
      )}

      { product.is_subscribable && !customerIsMember
          && (
          <AddToCartButton product={product} className="btn btn-subscription ms-lg-3" subscription>
            Subscribe & Save
            {' '}
            {product.calculated_subscription_savings_percentage}
          </AddToCartButton>
          )}

      { product.is_subscribable && customerIsMember
          && (
          <AddToCartButton product={product} className="btn btn-subscription ms-lg-3" subscription>
            Subscribe
          </AddToCartButton>
          )}
    </>
  )
}

ProductUtilityActions.propTypes = {
  product: PropTypes.shape({
    status: PropTypes.string,
    quantifiable: PropTypes.bool,
    is_applicable: PropTypes.bool,
    button_text: PropTypes.string,
    application_url: PropTypes.string,
    is_purchasable: PropTypes.bool,
    price: PropTypes.string,
    is_subscribable: PropTypes.bool,
    calculated_subscription_savings_percentage: PropTypes.string,
  }).isRequired,
  customerIsMember: PropTypes.bool,
  restricted: PropTypes.bool,
  accessUrl: PropTypes.string.isRequired,
}
ProductUtilityActions.defaultProps = {
  customerIsMember: null,
  restricted: null,
}
